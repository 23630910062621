import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { colors } from "../../tailwind";
import { FaAngleRight } from "react-icons/fa";
import tw from "tailwind.macro";

const BreadCrumbWrapper = styled.div`
  ${tw`pl-2`};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  color: ${colors["orange-light"]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CurrentLink = styled.div`
  color: ${colors["grey-darker"]};
  ${tw`md:max-w-full sm: max-w-48`};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LinkWrapper = styled.div`
  ${tw`text-center p-2 pl-0 font-sans text-lg lg:text-lg`};
  text-transform: capitalize;
`;

function pathNameToBreadCrumbList(pathName) {
  const pathArray = pathName.split("/");
  return pathArray.reduce(
    (prev, path) => {
      if (path === "") {
        return prev;
      }

      const prevPath = prev[prev.length - 1].path;

      return [
        ...prev,
        {
          path: `${prevPath !== "/" ? prevPath : ""}/${path}`,
          name: path.replace(/_/g, " ")
        }
      ];
    },
    [{ path: "/", name: "home" }]
  );
}

const BreadCrumbs = ({ location: { pathname } }) => (
  <BreadCrumbWrapper>
    {pathNameToBreadCrumbList(pathname).map(({ path, name }, index, list) => {
      const isNotLastIndex = index < list.length - 1;
      return (
        <LinkWrapper key={path}>
          {isNotLastIndex ? (
            <Link to={path}> {name} </Link>
          ) : (
            <CurrentLink> {name} </CurrentLink>
          )}
          {isNotLastIndex && (
            <FaAngleRight style={{ verticalAlign: "middle" }} />
          )}
        </LinkWrapper>
      );
    })}
  </BreadCrumbWrapper>
);

export default BreadCrumbs;
