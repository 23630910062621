import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Divider, DividerBlog } from '../../elements/Dividers'
import BlogContent from '../../elements/BlogContent'
import Inner from '../../elements/Inner'
import { UpDown, UpDownWide } from '../../styles/animations'
import { colors } from '../../../tailwind'
import SVG from '../../components/SVG'

const SidePannel = styled.div`
  ${tw`overflow-hidden relative items-center mt-0 w-64 min-h-screen sm: hidden md: hidden lg:flex flex-row xl:flex flex-row`}
  min-height: 100vh;
  align-items: stretch;
`
const ContainerMain = styled.div`
  ${tw`flex flex-row pb-32`};
  position: relative;
`
const PostWrapper = ({ children }) => (
  <ContainerMain>
    <SidePannel>
      <UpDownWide>
        <SVG icon="box" hiddenMobile width={6} fill={colors.blue} left="50%" top="75%" />
        <SVG icon="upDown" hiddenMobile width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
        <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
        <SVG icon="upDown" hiddenMobile width={4} fill={colors.orange} left="1%" top="80%" />
      </UpDownWide>
      <SVG icon="circle" width={6} fill={colors.white} left="4%" bottom="20%" />
      <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
    </SidePannel>
    <BlogContent>
      <Inner>{children}</Inner>
    </BlogContent>
    <SidePannel>
      <UpDownWide>
        <SVG icon="arrowUp" hiddenMobile width={16} fill={colors.purple} left="5%" top="80%" />
        <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
        <SVG icon="circle" width={6} fill={colors.white} left="60%" top="15%" />
      </UpDownWide>

      <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
      <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
      <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="60%" top="70%" />
    </SidePannel>
  </ContainerMain>
)

export default PostWrapper
PostWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}
