import React from 'react'
import { Link, graphql, withPrefix } from 'gatsby'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { Title } from '../elements/Titles'
import PostListWrapper from '../views/blog/PostListWrapper'
import BreadCrumbs from '../components/Breadcrumbs'
// import serverlessarchitecture from "../images/serverless_nodejs_graphql/serverlessarchitecture.png";
// Components
import Layout from '../components/Layout'
import Footer from '../components/Footer'

const AboutSub = styled.span`
  ${tw`tracking-normal antialiased subpixel-antialiased font-sans`}
  ${tw`flex flex-row text-black pt-12 lg:pt-0 lg:pl-12 text-xl sm: text-sm`};
`
const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8 pb-8`};
`

const Avatar = styled.img`
  ${tw`h-auto block w-24 sm: w-full md: w-1/2 lg: w-64`};
  padding: 1px;
`

const DateFormatter = styled.div`
  ${tw`text-l`}
`

const Article = styled.div`
  ${tw`shadow-lg relative no-underline rounded-lg pr-4 py-2 text-black pl-16 sm: pl-2 pr-2 mx-2`};
  margin-bottom: 40px;
`
const Blog = props => {
  const postList = props.data.allMarkdownRemark
  return (
    <>
      <Layout location={props.location} />

      <PostListWrapper>
        <BreadCrumbs location={props.location} />
        <br />
        {postList.edges.map(({ node }, i) => (
          <Link to={node.fields.slug} className="link" key={node.fields.slug}>
            <Article>
              <Title>{node.frontmatter.title}</Title>

              <AboutHero>
                <Avatar src={withPrefix(`/images${node.frontmatter.image}`)} alt="" />
                <AboutSub>{node.frontmatter.description}</AboutSub>
              </AboutHero>
              <DateFormatter>
                <span>Published : {node.frontmatter.date}</span>
                <p>{node.excerpt}</p>
              </DateFormatter>
            </Article>
          </Link>
        ))}
      </PostListWrapper>

      <Footer position="relative" />
    </>
  )
}
export default Blog
export const listQuery = graphql`
  query ListQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            date(formatString: "MMMM Do YYYY")
            title
            image
            description
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
