import styled from 'styled-components'
import tw from 'tailwind.macro'

const BlogContent = styled.div`
  ${tw`p-6 justify-left min-h-screen p-0 items-start flex z-50 flex flex-col mt-16`};
  width:100%;
  padding-bottom:110px;
`

export default BlogContent
